import React from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";

function MaintenanceModal({ open, onClose }) {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="maintenance-title" aria-describedby="maintenance-description">
      <Box sx={styles.modalBox}>
        <ConstructionIcon sx={styles.icon} />
        <Typography id="maintenance-title" variant="h5" sx={styles.title}>
          Maintenance in Progress
        </Typography>
        <Typography id="maintenance-description" sx={styles.description}>
          We are currently under maintenance to improve your experience. Please check back soon. Thank you for your patience!
        </Typography>
        <Button variant="contained" onClick={onClose} sx={styles.button}>
          Close
        </Button>
      </Box>
    </Modal>
  );
}

const styles = {
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: "0px 8px 50px rgba(0, 0, 0, 0.3)", // Sombra pronunciada
    border: "none", // Quitar cualquier borde
    borderRadius: 10,
    p: 4,
    textAlign: "center",
  },
  icon: {
    fontSize: 50,
    color: "#f57c00",
    mb: 2,
  },
  title: {
    mb: 2,
    fontWeight: "bold",
  },
  description: {
    mb: 3,
    color: "text.secondary",
  },
  button: {
    backgroundColor: "#1976d2",
    "&:hover": {
      backgroundColor: "#115293",
    },
  },
};

export default MaintenanceModal;
